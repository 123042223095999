import utils from "@/utilities/Utils";
import { mapData } from "@/utilities/DataMapping";

export interface IProperty {
    id: string;
    address1: string;
    address2: string;
    address3: string;
    postTown: string;
    postcode: string;
    storeys: number;
    bedSpaces: number;
    siteContactName: string;
    siteContactJobTitle: string;
    siteContactEmailAddress: string;
    siteContactPhoneNumber: string;
    isOver18Meters: boolean;
    created: Date;
    createdByUserID: string;
    lastUpdated: Date;
    lastUpdatedByUserID: string;
}

export class Property implements IProperty {

    constructor(data?: IProperty) {
        if (data) this.update(data);
    }

    update(data: IProperty) {
        mapData(data, { root: () => this });
    }

    id: string = utils.emptyGuidValue;
    address1: string = "";
    address2: string = "";
    address3: string = "";
    postTown: string = "";
    postcode: string = "";
    storeys: number = 0;
    bedSpaces: number = 0;
    siteContactName: string = "";
    siteContactJobTitle: string = "";
    siteContactEmailAddress: string = "";
    siteContactPhoneNumber: string = "";
    isOver18Meters: boolean = false;
    created: Date = new Date(utils.emptyDateValue);
    createdByUserID: string = utils.emptyGuidValue;
    lastUpdated: Date = new Date(utils.emptyDateValue);
    lastUpdatedByUserID: string = utils.emptyGuidValue;
}
