
























































import Vue from "vue";
import Component from "vue-class-component";
import ApiButton from "@/vue/components/ApiButton.vue";
import apiClient from "@/utilities/ApiClient";
import ConfirmDialogue from "@/vue/components/ConfirmDialogue.vue";
import store from "@/store/store";
import utils from "@/utilities/Utils";
import { ISaveResponse } from "@/model/ISaveResponse";
import { IProperty, Property } from "@/model/Property";
import { Ref } from "vue-property-decorator";
import { UserRole } from "@/model/Enums";
import { VForm } from "@/vForm";
import { Watch } from "vue-property-decorator";
import * as toastr from "toastr";

@Component({ components: { 
        ApiButton,
        ConfirmDialogue 
    } 
})

export default class BuildingDialogue extends Vue {

    //
    // -- properties
    //

    @Ref("buildingForm") private readonly buildingForm!: VForm;  

    private showDialogue: boolean = false;
    private dialogueTitle: string = "";
    private building: Property = new Property();
    isNew: boolean = false;

    private get isLandlordPrimary() {
        return store.state.signedInUser?.roleID == UserRole.Landlord;
    }

    private get incompleteVerifications() {
        return store.state.signedInUser?.incompleteVerifications;
    }

    //
    // -- validations
    //

    private emailErrorMessages: Array<string> = [];

    @Watch("building.siteContactEmailAddress")
    private async validateEmail() {
        this.emailErrorMessages = [];
        if (utils.isEmptyOrWhitespace(this.building.siteContactEmailAddress))  return;

        const response: { isOkay: boolean; message: string } = await apiClient.get(`/api/email/emailFormat?email=${this.building.siteContactEmailAddress}`);
        if (response.isOkay) {
            this.emailErrorMessages = [];
        }
        else if (!response.isOkay && this.emailErrorMessages.indexOf(response.message) == -1) {
            this.emailErrorMessages = [];
            this.emailErrorMessages.push(response.message);
            return;
        }
    }

    private phoneErrorMessages: Array<string> = [];

    @Watch("building.siteContactPhoneNumber")
    private async validatePhone() {
        this.phoneErrorMessages = [];
        if (utils.isEmptyOrWhitespace(this.building.siteContactPhoneNumber))  return;

        const response: { isOkay: boolean; message: string } = await apiClient.get(`/api/email/phoneFormat?phone=${this.building.siteContactPhoneNumber}`);
        if (response.isOkay) {
            this.phoneErrorMessages = [];
        }
        else if (!response.isOkay && this.phoneErrorMessages.indexOf(response.message) == -1) {
            this.phoneErrorMessages = [];
            this.phoneErrorMessages.push(response.message);
            return;
        }
    }

    //
    // -- methods
    //

    add() {
        this.reset();
        this.dialogueTitle = "Add Building";
        this.isNew = true;
        this.showDialogue = true;
    }

    async edit(id: string) {
        this.reset();
        this.dialogueTitle = "Edit Building";
        this.isNew = false;
        const buildingData: IProperty = await apiClient.get(`api/building/Load?id=${id}`);
        this.building.update(buildingData);
        this.showDialogue = true;
    }

    private reset() {
        utils.resetObject(this.building);
        this.building.id = utils.emptyGuidValue;
        this.buildingForm?.resetValidation();
    }

    private async save() {
        await this.validateEmail();
        await this.validatePhone();
        const isValid = this.buildingForm.validate() && this.emailErrorMessages.length === 0 && this.phoneErrorMessages.length === 0;
        if (!isValid) {
            toastr.warning("Please fix highlighted issues", "Cannot Save");
            return;
        }

        var postData = {
            building: this.building,
            user: store.state.signedInUser
        }

        const response: ISaveResponse = await apiClient.post("/api/building/save", postData);
        toastr.success("Saved");
        this.$emit("reload");    
        this.showDialogue = false;
    }

    private confirmLapse() {
        const dialog: ConfirmDialogue = this.$refs.lapseDialogue as ConfirmDialogue;
        dialog.show();
    }

    private async doLapse() {
        await apiClient.post("/api/building/lapse", this.building);
        toastr.warning("Lapsed");
        this.$emit("reload");
        this.showDialogue = false;
    }
}
