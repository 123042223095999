
































import Vue from "vue";
import Component from "vue-class-component";
import ApiButton from "@/vue/components/ApiButton.vue";
import apiClient from "@/utilities/ApiClient";
import BuildingDialogue from "@/vue/components/BuildingDialogue.vue";
import VerifierVisitDialogue from "@/vue/components/VerifierVisitDialogue.vue";
import store from "@/store/store";
import { IVerificationTask, VerificationTask } from "@/model/VerificationTask";
import { UserRole } from "@/model/Enums";
import { VerificationVM, IVerificationVM } from "@/model/Api/VM/VerificationVM";
import * as toastr from "toastr";

@Component({
    components: { 
        ApiButton,
        BuildingDialogue,
        VerifierVisitDialogue
    }
})
export default class Verifications extends Vue {

    async mounted() {
        this.loadVerifications();
    }

    //
    // -- properties
    //

    private verificationHeaders = [
        { text: "Property", value: "propertyDetail", sortable: true },
        { text: "Organisation", value: "organisation", sortable: true },
        { text: "Verifier", value: "verifier", sortable: true },
        { text: "Type", value: "typeDescription", sortable: true },
        { text: "Visit Date", value: "verificationTask.visitDateTime", sortable: true },
        { text: "Status", value: "propertyAssessmentStatus", sortable: true },
        { text: "Actions", value: "actionCount", sortable: true },
        { text: "Action Required", value: "", sortable: true }            
    ];

    private verifications: Array<IVerificationVM> = [];

    private get isVerifier() {
        return store.state.signedInUser?.role == UserRole.Verifier;
    }

    private get isLandlord() {
        return store.state.signedInUser?.role == UserRole.Landlord || store.state.signedInUser?.role == UserRole.LandlordUser;
    }

    //
    // -- methods
    //

    private async loadVerifications() {
        this.verifications = [];
        if (this.isLandlord) {
            const response = await apiClient.get(`/api/assessment/loadVerificationsByLandlord?landlordID=${store.state.signedInUser?.landlordID}&userID=${store.state.signedInUser?.id}`);
            this.verifications.push(...response.map((v: IVerificationVM) => new VerificationVM(v)));
        } else {
            const response = await apiClient.get(`/api/assessment/loadVerificationsByVerifier?id=${store.state.signedInUser?.id}`);
            this.verifications.push(...response.map((v: IVerificationVM) => new VerificationVM(v)));
        }
    }

    edit(verification: IVerificationVM) {
        if (verification.assessmentSubmitted && this.isVerifier) {
            this.$router.push("/verification/" + verification.verificationTask.id);
        } else if (this.isLandlord) {
            this.$router.push("/verification/" + verification.verificationTask.id);
        } else {
            toastr.warning("The Landlord has not yet submitted their SAQ.")
        }
    }

    setVisitDate(verification: VerificationVM) {
        const dialog: VerifierVisitDialogue = this.$refs.verifierVisitDialogue as VerifierVisitDialogue;
        dialog.open(verification.propertyDetail, verification.verificationTask);
    }
}
