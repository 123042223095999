var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card-title',[_c('div',{staticClass:"text-h4"},[_vm._v("Verifications")]),_c('v-spacer')],1),_c('v-data-table',{attrs:{"headers":_vm.verificationHeaders,"items":_vm.verifications,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.propertyDetail",fn:function(ref){
var item = ref.item;
return [_c('div',{on:{"click":function($event){return _vm.edit(item)}}},[_vm._v(_vm._s(item.propertyDetail))])]}},{key:"item.organisation",fn:function(ref){
var item = ref.item;
return [_c('div',{on:{"click":function($event){return _vm.edit(item)}}},[_vm._v(_vm._s(item.organisation))])]}},{key:"item.verifier",fn:function(ref){
var item = ref.item;
return [_c('div',{on:{"click":function($event){return _vm.edit(item)}}},[_vm._v(_vm._s(item.verifier))])]}},{key:"item.typeDescription",fn:function(ref){
var item = ref.item;
return [_c('div',{on:{"click":function($event){return _vm.edit(item)}}},[_vm._v(_vm._s(item.typeDescription))])]}},{key:"item.verificationTask.visitDateTime",fn:function(ref){
var item = ref.item;
return [(_vm.isVerifier)?_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.setVisitDate(item)}}},[_c('v-icon',[_vm._v("mdi-calendar")]),_vm._v(" "+_vm._s(_vm._f("dateText")(item.verificationTask.visitDateTime))+" ")],1):_vm._e(),(_vm.isLandlord)?_c('div',[_c('v-icon',[_vm._v("mdi-calendar")]),_vm._v(" "+_vm._s(_vm._f("dateText")(item.verificationTask.visitDateTime))+" ")],1):_vm._e()]}},{key:"item.propertyAssessmentStatus",fn:function(ref){
var item = ref.item;
return [_c('div',{on:{"click":function($event){return _vm.edit(item)}}},[_vm._v(_vm._s(item.propertyAssessmentStatus))])]}},{key:"item.actionCount",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.completedActionCount)+"/"+_vm._s(item.actionCount))]}}],null,true)}),_c('verifier-visit-dialogue',{ref:"verifierVisitDialogue",on:{"reload":_vm.loadVerifications}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }