import utils from "@/utilities/Utils";
import { mapData } from "@/utilities/DataMapping";

export interface IVerificationTask {
    id: string;
    landlordID: string;
    landlordAssessmentID: string;
    propertyID: string;
    verifierID: string;
    propertyAssessmentID: string;
    automaticTypeID: string;
    typeID: string;
    visitDateTime: Date;
    disputeUnresolvableReason: string;
    createdDate: Date;
    createdByUserID: string;
    lastModifiedDate: Date;
    lastModifiedByUserID: string;
}

export class VerificationTask implements IVerificationTask {

    constructor(data?: IVerificationTask) {
        if (data) this.update(data);
    }

    update(data: IVerificationTask) {
        mapData(data, { root: () => this });
    }

    id: string = utils.emptyGuidValue;
    landlordID: string = utils.emptyGuidValue;
    landlordAssessmentID: string = utils.emptyGuidValue;
    propertyID: string = utils.emptyGuidValue;
    verifierID: string = utils.emptyGuidValue;
    propertyAssessmentID: string = utils.emptyGuidValue;
    automaticTypeID: string = utils.emptyGuidValue;
    typeID: string = utils.emptyGuidValue;
    visitDateTime: Date = new Date(utils.emptyDateValue);
    disputeUnresolvableReason: string = "";
    createdDate: Date = new Date(utils.emptyDateValue);
    createdByUserID: string = utils.emptyGuidValue;
    lastModifiedDate: Date = new Date(utils.emptyDateValue);
    lastModifiedByUserID: string = utils.emptyGuidValue;

    pdfReportButtonText: string = "Download to PDF";
    pdfReportIsLoading: boolean = false;
}
