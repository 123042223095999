import { IVerificationTask, VerificationTask } from "../../VerificationTask";
import { mapData } from "@/utilities/DataMapping";

export interface IVerificationVM {
    verificationTask: IVerificationTask;
    propertyDetail: string;
    organisation: string;
    verifier: string;
    typeDescription: string;
    propertyAssessmentStatus: string;
    assessmentSubmitted: boolean;
    actionCount: number;
    completedActionCount: number;
}

export class VerificationVM implements IVerificationVM {

    constructor(data?: IVerificationVM) {
        if (data) this.update(data);
    }

    update(data: IVerificationVM) {
        mapData(data, { 
            root: () => this,
            verificationTask: () => new VerificationTask()
        });
    }

    verificationTask: any = new VerificationTask();
    propertyDetail: string = "";
    organisation: string = "";
    verifier: string = "";
    typeDescription: string = "";
    propertyAssessmentStatus: string = "";
    assessmentSubmitted: boolean = false;
    actionCount: number = 0;
    completedActionCount: number = 0;
}
