import { render, staticRenderFns } from "./VerifierVisitDialogue.vue?vue&type=template&id=64354582&scoped=true&"
import script from "./VerifierVisitDialogue.vue?vue&type=script&lang=ts&"
export * from "./VerifierVisitDialogue.vue?vue&type=script&lang=ts&"
import style0 from "./VerifierVisitDialogue.vue?vue&type=style&index=0&id=64354582&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64354582",
  null
  
)

export default component.exports