











































import utils from "@/utilities/Utils";
import moment from "moment";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import ApiButton from "@/vue/components/ApiButton.vue";

@Component({ components: { ApiButton } })
export default class DatePicker extends Vue {

    // properties

    @Prop({ default: ''}) private value!: Date|null;
    @Prop({ required: true }) private label!: string;
    @Prop({ default: [] }) private rules!: any;
    
    pickerVisible: boolean = false;
    date: string|null = null;

    // computed properties

    private get stringValue(): string|null {
        return this.value != null 
            ? moment(this.value).format("YYYY-MM-DD")
            : null;
    }

    private set stringValue(value: string|null) {
        let output: Date|null = null;
        if (!value) output = null
        else if (isNaN(+value)) {
            const m = moment(value, moment.ISO_8601, true);
            if (m.isValid()) {
                if (value.substring(0, 4) === "0001") {
                    output = new Date(-62135596800000); // 01/01/0001 means 'no value'
                }
                output = m.toDate(); // could leave as moment?
            }
        }

        this.$emit('input', output);
    }

    private get selected() {
        return this.stringValue ?? ""
    }
    private set selected(value: string) {
        this.stringValue = value;
    }

    private get dateFormatted () {
        return utils.dateText(new Date(this.selected), "")
    }
    private set dateFormatted(value: string) {
        if (utils.isEmptyOrWhitespace(value))
            this.$emit('input', null);
    }

    // watchers

    @Watch("date") 
    private onDateChanged(value: string) {
        this.stringValue = this.formatDate(this.date)
    }

    // methods

    private formatDate (date: string|null) {
        if (!date) { return null }
        return date
    }

    private parseDate (date: string|Date|null) {
        if (!date) { return null }

        if (typeof(date) == "string") {
            const [year, month, day] = date.split('-')
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        }

        if (typeof(date) == "object") {
            const m = moment(date);
            return m.isValid() ? m.format("YYYY-MM-DD") : null
        }
    }

    private selectToday(){
        this.stringValue = moment(new Date()).format("YYYY-MM-DD");
        this.pickerVisible = false;
    }

}

